/* You can add global styles to this file, and also import other style files */


// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// kendo-angular-progressbar
@import "@progress/kendo-theme-default/scss/progressbar/_index.scss";
@import '~styles/theme';
@import '~styles/styles';
@import '~styles/custom-component-themes';
@import "~@ng-select/ng-select/themes/material.theme.css";
//@import "~@ng-select/ng-select/themes/default.theme.css";

@include angular-material-theme($dark-theme);
@include custom-components-theme($dark-theme);
@include angular-material-typography($typography);

.light-theme {
    @include angular-material-color($light-theme);
    @include custom-components-theme($light-theme);
}


// @import '~src/styles/theme1';
// @include angular-material-theme($theme);
// .light-theme {
//   @include angular-material-color($altTheme);
// }

html,
body {
    font-size: 12px;
    width: 100%;
    /* height: 100%; */
    height: 100%;
    background-color: black;
    overflow-y: hidden;
}

@media only screen and (max-height: 580px) {
    body {
        overflow-y: scroll;
    }
}

* {
    font-family: 'EYInterstate-Light';
}

.main-container {
    //margin: 10px 10px 0 10px;
    margin: 5px 3px 0 0;

    .row {
        margin: 0 0 10px 0 !important;
    }
}

.fa,
.far,
.fas {
    font-family: "Font Awesome 5 Free" !important;
}

@font-face {
    font-family: 'EYInterstate-Bold';
    src: url('/assets/fonts/EYInterstate-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-BoldItalic';
    src: url('/assets/fonts/EYInterstate-BoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-Italic';
    src: url('/assets/fonts/EYInterstate-Italic.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-Light';
    src: url('/assets/fonts/EYInterstate-Light.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-LightBold';
    src: url('/assets/fonts/EYInterstate-LightBold.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-LightBoldIt';
    src: url('/assets/fonts/EYInterstate-LightBoldIt.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-LightItalic';
    src: url('/assets/fonts/EYInterstate-LightItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'EYInterstate-Regular';
    src: url('/assets/fonts/EYInterstate-Regular.ttf') format("truetype");
}

/* Popup Css in ServiceDashboard starts*/
.cdk-overlay-container {
    z-index: 2000 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-header {
    background: cadetblue;
    color: white;
    margin: 0px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem 1rem 0;
}

.modal-content {
    border-radius: 12px;
    max-height: 100%;
}

.modal-height {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-dialog {
    display: flex;
    align-items: center;
    height: calc(100% - 42px);
}

.modal-footer {
    button {
        margin-right: 10px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.modal-data {
    padding: 10px 10px 20px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */

.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */

.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #FFFFFF;
}

.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
    height: 32px;
    width: 32px;
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #ffe600;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 42rem;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    color: #FFFFFF;
    border-radius: 0;
    box-shadow: none;
    height: 44px;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */

.toast-info {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */

.toast-error {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */

.toast-success {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */

.toast-warning {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51A351;
}

.toast-error {
    background-color: #BD362F;
}

.toast-info {
    background-color: #2F96B4;
}

.toast-warning {
    background-color: #F89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */

@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

// colors
$bodyBackground: #212528;
$cardBackground: #2b2f32;
$white: #FFFFFF;
$dark: #000000;
$bluePrimary: #15abde;
$green: #9cc33b;
$transparent: transparent;
$headingColor: #7e8285;
$paraColor: #e0e4e4;
$lightWhite: rgba(255, 255, 255, 0.1);
$lightWhite7: rgba(255, 255, 255, 0.7);
$buttonblue: #41d6c3;
$textGreen: #2f928c;

$borderRadius5 : 5px;
$borderRadius10 : 10px;

@mixin commonLabel($fontSize,
    $color,
    $fontWeight,
    $letterSpacing,
    $textTransform,
    $fontFamily) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
}

@mixin commonSpan($fontSize,
    $color,
    $fontWeight,
    $letterSpacing,
    $textTransform,
    $fontFamily) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
}

@mixin commonPara($fontSize,
    $color,
    $fontWeight,
    $letterSpacing,
    $textTransform,
    $fontFamily) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
}

@mixin textEllipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin commonButton($fontSize,
    $color,
    $fontWeight,
    $letterSpacing,
    $textTransform,
    $fontFamily,
    $padding,
    $margin,
    $background) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
    padding: $padding;
    margin: $margin;
    background: $background;
}

@mixin commonCard {
    border-radius: $borderRadius10;
}

// common fonts
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font19: 19px;
$font20: 20px;

.bgColor {
    background-color: $cardBackground;
}

.bgTransparent {
    background: $transparent;
}

.btn-theme {
    @include commonButton($font12, rgba(0, 0, 0, 0.7), 600, unset, unset, 'EYInterstate-Regular', 0 10px !important, 0 5px !important, $textGreen);
    line-height: 30px;
}

.small-button {
    line-height: 23px;
    height: 100%;
    font-size: $font10;
}

.fl-1 {
    flex: 1;
}

.roundness {
    @include commonCard;
}

//telemetry popup css

.telemetry-popup {
    min-width: calc(100% - 250px) !important;
}

.dropdown-menu {
    min-width: calc(100% - 10px) !important;
}

.dropdown {
    ul {
        padding: 0px !important;

        li {
            padding: 5px 10px;

            span {
                padding-left: 5px;
            }
        }

    }
}

//telemetry popup css

.telemetry-popup {
    min-width: calc(100% - 400px) !important;
}

.bread-crumb-container {
    margin: 15px 0 0 0;

    .CV-breadcrumb {
        .CV-breadcrumb-item {
            list-style: none;

            .breadcrumb-root-name {
                font-size: 16px;
                color: $white;
                text-decoration: none;
                font-weight: 400;
            }

            a.active {
                text-decoration: none;
            }
        }
    }
}

.bread-crumb-with-child-container {
    padding: 10px 0;

    .CV-breadcrumb {
        color: $white;
        font-size: 12px !important;
        list-style: none;
        padding-left: 28px;
        display: inline-flex;
        position: relative;
        margin-bottom: 0;

        .CV-breadcrumb-item {
            .breadcrumb-child-name {
                font-size: 12px;
                margin: 0 5px;
                color: $white;
            }

            :hover {
                text-decoration: underline;
                cursor: pointer;
            }

            a.active {
                text-decoration: underline;
                color: $white !important;
            }
        }
    }
}

.network-demand-container {
    .modal-action-buttons {
        text-align: right;
        margin-bottom: 8px;

        .mat-button {
            border-radius: 0;
            // width: auto;
            // height: 30px;
            // font-size: 16px;
            // font-weight: 700;

            &:nth-child(1) {
                color: #BE4603;
                border: 1px solid #BE4603;
            }

            &:nth-last-child(1) {
                background-color: #3737FF;
            }
        }
    }
}



// .dropdown styles

.tab-container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    background: #131329;
    border-radius: 8px;

    .dropdown-lbl {
        padding-right: 5px;
        color: #D7D7DC;
    }

    .dropdown-select {
        height: 35px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 8px;
        background: #25254C;
        color: #BDC4FF;
        border: none;
        cursor: pointer;
    }

    .mat-input-element:not(.mat-native-select-inline) option {
        color: #BDC4FF !important;
    }

    .refresh-btn {
        background: #25254C;
        gap: 0px;
        border-radius: 8px;
        opacity: 0px;
    }

    .cached-icon {
        color: #D7D7DC;
        width: 30px;
        height: 24px;
    }

    .dropdownCol {
        align-self: center;
        padding-right: 20px !important;
    }
}


.font-regular {
    font-family: 'EYInterstate-Regular';
}

.font-bold {
    font-family: 'EYInterstate-Bold';
}

.font-Light {
    font-family: 'EYInterstate-Light';
}

.font-14 {
    font-size: 14px;
}

.mat-button-toggle {
    cursor: pointer;
}



.mdc-list-item__primary-text {
    display: flex !important;
    align-items: center !important;
}

.mat-mdc-list-item span.mat-mdc-list-item-unscoped-content {
    color: #c4c4cd;

    &:hover {
        color: #fff;
    }

    mat-icon {
        height: 50px;
        min-width: 50px;
        text-align: center;
        line-height: 50px;
        /* color: #4f4f6c; */
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    p {
        word-break: break-all;
        margin: 0;
        font-size: 14px
    }
}

.mat-sidenav {
    transition: width 0.3s ease;
    overflow: hidden;
}

.mat-sidenav-content {
    transition: margin-left 0.3s ease;
}

.d-flex {
    display: flex;
}


.align-center {
    align-items: center
}

.sidebar {
    .menu-container {
        .submenu {
            .mat-mdc-list-item {
                .mat-mdc-list-item-unscoped-content {
                    padding-left: 60px;
                    font-size: 14px;
                }
            }
        }
    }
}

.mat-ripple-element {
    display: none;
}

.date-picker-row,
.parts-dropdown {
    display: grid;

    mat-form-field.mat-focused,
    mat-form-field.mat-form-field-should-float {
        .mat-form-field-infix {
            margin-top: 6px;
        }

    }

    mat-form-field.mat-focused {
        .mat-select-arrow-wrapper {
            margin-bottom: -2px !important;
        }
    }

    mat-form-field.mat-form-field-should-float {
        .mat-select-arrow-wrapper {
            margin-bottom: 14px;
        }
    }

    mat-form-field.mat-focused.mat-primary .mat-select-arrow {
        color: #c4c4cd;
        margin-top: -16px;
    }

    mat-form-field {
        margin-right: 14px;
        width: 100%;
        border-radius: 8px;
        background: transparent;
        height: 46px;
        /* display: flex; */
        align-items: center;
        border: 1px solid #656579;
        position: relative;

        .mat-form-field-wrapper {
            .mat-select {
                display: inline-block;
                width: 100%;
                outline: none;
                line-height: 26px;
                font-size: 14px;
                font-family: 'EYInterstate-Regular';
                padding: 0 12px 0 14px;
            }

            .mat-form-field-infix {
                label {
                    mat-label {
                        color: #c4c4cd;
                        font-size: 14px;
                        font-family: EYInterstate-Light;
                        padding-left: 20px;
                    }
                }
            }

        }
    }

    .mat-form-field-type-mat-date-range-input.mat-form-field-should-float,
    .mat-form-field-type-mat-date-range-input.mat-form-field-should-float.mat-focused {
        .mat-form-field-infix {
            margin-top: 7px;
        }

        .mat-button-wrapper svg {
            margin-top: -12px;
        }
    }



    .mat-date-range-input {
        color: #9c82d4 !important;
        font-size: 14px !important;

        .mat-date-range-input-separator {
            color: #9c82d4;
        }

        .mat-date-range-input-container {
            padding-left: 14px;
        }
    }

    .mat-date-range-input-wrapper {
        font-family: 'EYInterstate-Light';
    }

    .mat-datepicker-toggle {
        .mat-focus-indicator {
            height: 3.5em;
            width: 3.5em;
            display: flex;
            align-items: center;
            padding-top: 12px;
            padding-left: 7px;

            svg {
                font-size: 24px;

                path {
                    fill: #9c82d4;
                }
            }
        }


    }

    .mat-mdc-select-arrow-wrapper {
        svg path {
            fill: #9c82d4;

        }
    }

}

.parts-dropdown {
    .mat-mdc-form-field {
        .mat-mdc-select {
            color: #9c82d4
        }
    }
}




.dropdown-header {
    // padding: 10px 0 10px 20px !important;
    width: 100%;

    .action-dropdowns {
        padding: 20px 14px 18px 14px;
        background-color: #2a2a69ab;
        border: 1px solid #ffffff1a;

        mat-form-field {
            .mat-form-field-underline {
                background-color: unset;
                display: none;

                .mat-form-field-ripple {
                    background-color: transparent;
                }
            }
        }

        label {
            font-size: 14px;
            font-family: "EYInterstate-Regular";
            color: #AEAEC6;
        }

        .summary-report-button {
            padding-bottom: 0;
            justify-content: flex-start;
            padding-top: 28px;

            .update-button button {
                height: 44px;
                background-color: #35a4eb !important;
                width: auto;
                // margin-right: 10px;
            }

            .summary-report-button {
                height: 44px;
            }
        }

        // .update-button {
        //     align-self: center;

        //     button {
        //         height: 46px;
        //     }
        // }
    }

    // .summary-report-button {
    //     display: flex;
    //     justify-content: end;
    //     padding-bottom: 14px;

    //     button {
    //         background: #ff9831;
    //         width: 195px;
    //         float: right;
    //         display: flex;
    //     }
    // }
}

.bar-charts {
    background-color: #181830 !important;
    padding-top: 10px;
    border-radius: 8px;

    .header-div {
        font-family: "EYInterstate-Regular";
        margin-top: 10px;
        display: inline-flex;
        padding: 8px 22px;
        align-items: center;
        gap: 10px;
        border-radius: 0px 4px 4px 0px;
        background: #25254C;
        color: #BDC4FF;
        font-size: 16px;
    }
}



.charts-card-grid {
    // border: 1px solid #ffffff3a;
    // padding: 10px;
}

.mdc-menu-surface--open {
    background-color: #2e2e38 !important;
    color: #c4c4cd;

    mat-option {
        color: #c4c4cd !important;

        &:hover {

            background-color: #ffffff2a !important;

        }
    }

}

.mat-mdc-option.mat-mdc-option-active {
    background-color: #00000057 !important;
}

.mdc-list-item--selected.mat-mdc-option-active {
    background-color: #00000057 !important;
}

.sku-main-page {
    padding: 16px;

    .dropdown-header .action-dropdowns {
        mat-form-field {
            .mat-form-field-infix {
                margin-top: -2px;
            }
        }

        .search-bar {
            display: grid;

            mat-form-field {
                .mat-form-field-wrapper {
                    border-radius: 8px;
                    background: transparent;
                    height: 46px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #656579;
                    padding: 0;

                    .mat-form-field-flex {
                        align-items: center;

                        mat-icon {
                            font-size: 26px;
                            color: #AEAEC6;
                            padding: 5px 38px 0px 12px;
                        }

                        .mat-form-field-infix {
                            padding-bottom: 12px;

                            .mat-form-field-label {
                                color: #AEAEC6;
                            }
                        }
                    }

                    input {
                        color: #c4c4cd;
                        font-size: 14px;
                        font-family: "EYInterstate-Light";
                    }

                    .mat-mdc-input-element::placeholder {
                        color: #c4c4cd;
                        /* Change the color value to whatever color you prefer */
                    }
                }

                .mat-form-field-label-wrapper {
                    top: -1rem;
                }
            }

            mat-form-field.mat-form-field-should-float {
                .mat-form-field-infix {
                    padding-bottom: 0 !important;
                }
            }
        }
    }
}

.forecast-table-container {
    table {
        background-color: #2e2e38;
        display: block;
        width: 100%;
        overflow-x: auto;
        max-height: 600px;

        thead tr {
            background-color: #1A1A24;

            th:nth-child(1) {
                min-width: 10rem;
            }

            .mat-header-cell {
                color: #D7D7DC;
                font-family: 'EYInterstate-Regular';
                white-space: nowrap;
                width: 1%;
                padding: 0 16px 0 16px !important;
                border-bottom-width: 0;
            }
        }

        tbody {
            tr.mat-mdc-row {
                color: #c4c4cd;
            }

            tr:nth-child(even) {
                background-color: #23232f;
            }

            tr:nth-child(odd) {
                background-color: #2e2e38;
            }

            tr {

                .mat-column-Values,
                .mat-column-Plant {
                    white-space: nowrap;
                }

                td:nth-child(1) {
                    a {
                        color: #35A4E8;
                        cursor: pointer;
                    }
                }
            }

            td {
                font-family: 'EYInterstate-Light';
                padding: 0 16px 0 16px !important;
                border-bottom-width: 0;
                color: #D7D7DC;
            }
        }

    }

    mat-paginator {
        .mat-mdc-paginator-container {
            background-color: #2e2e38;
            color: #c4c4cd;
            font-size: 14px;

            .mat-mdc-select-trigger {
                color: #c4c4cd;

                .mat-mdc-select-arrow svg {
                    fill: #c4c4cd;
                }
            }

            .mat-mdc-icon-button svg {
                fill: #c4c4cd;
            }

            .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
                fill: #585860;
            }
        }
    }
}

.forecast-table-container.sku-review-table {
    table {
        thead tr {
            th:nth-child(1) {
                min-width: 10rem;
            }
        }
    }
}

.table-container.model-table {
    table {
        padding: 0;

        tr th {
            width: 18vw;
        }
    }
}

.mat-button-toggle-appearance-standard {
    border: 1px solid #5050A8;
    background-color: #131329;
    color: #B2B2B2;
    font-size: 9px;
    font-weight: 400;
}

.mat-button-toggle-checked {
    border: 1px solid #5050A8;
    color: #FFFFFF;
    background-color: #5050A8;
    font-size: 9px;
    font-weight: 400;
}

.mat-button-toggle-group {
    height: 32px;
    align-items: center;
}

.chart-card {
    //height: 403px;
    height: 100%;
    width: 100%;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
    background: #131329;
    box-shadow: 2px 2px 4px 0px #00000017;

    .header-div {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0px 10px 5px 0;

        .title {
            display: inline-flex;
            padding: 8px 22px;
            align-items: center;
            gap: 10px;
            border-radius: 0px 4px 4px 0px;
            background: #25254C;
            color: #BDC4FF;
            font-size: 16px;
        }
    }
}


// custom scrollbar

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #747480;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3A3A4A;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3A3A4A;
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .mat-mdc-form-field-type-mat-date-range-input {
        position: absolute;
        width: 44% !important;
    }

    .table-container.model-table {
        .row {
            margin: 0;
        }

        table {
            padding: 0;

            tr th {
                width: 32vw;
            }
        }
    }

    .map-container {
        display: inline-grid;
        height: 44rem !important;
        margin: 22px 0;
    }

    .forecast-container .app-cards {
        padding-top: 14px;
    }

    #power-gauge {
        text-align: center !important;
        padding-left: 0 !important;

        .gauge-value {
            left: 0 !important;
            right: -2%;
            top: 86% !important;
        }

        .gauge {
            margin-left: 15%;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 954px) {
    .sku-main-page .row .d-flex .separator {
        margin: 16px 34px 0px;

    }
}

@media screen and (min-width: 992px) {
    .mat-mdc-form-field-type-mat-date-range-input {
        position: absolute;
        width: 30% !important;
    }
}

@media screen and (min-width: 1200px) {
    .mat-mdc-form-field-type-mat-date-range-input {
        position: absolute;
        width: 22% !important;
    }
}

@media screen and (min-width: 1300px) and (max-width: 2000px) {
    .alerts-card-container {
        .alerts-cards {
            margin-top: 8% !important;

            .card {
                .card-body {
                    height: 20vh !important;

                    .mat-icon {
                        margin-right: 0px !important;
                        font-size: 45px !important;
                        width: 100px !important;
                        height: 100px !important;
                        align-self: center !important;
                        margin-top: 60px !important;
                    }

                    .card-text {
                        font-size: 35px !important;
                    }
                }

                .card-footer {
                    height: 12vh !important;
                    line-height: 1.3;
                    font-size: 20px !important;
                }
            }
        }

    }
}



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// .progress-container {
//     .label-wrapper {
//         position: absolute;
//         top: 103px;
//         left: -34px;
//     }

//     .progress-bar-treegraph {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 30px;
//         height: 30px;
//         border-radius: 50%;
//         margin-top: 94px;
//         background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(hotpink 75%, pink 0);
//         margin-left: 90px;
//         .percentage {
//             color: #000;
//         }
//     }
// }