.ey-ml-68 {
    margin-left: -68px;
}

.ey-ml-46 {
    margin-left: -46px;
}

.ey-digital-twin {
    top: -48px;
    position: absolute;
    width: calc(100% + 46px);
    height: calc(100% + 48px);
}

.ey-fs-20 {
    font-size: 20px;
}

.ey-sb-ac {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ey-pr {
    position: relative;
}

.ey-vc {
    display: flex;
    align-items: center;
}

.ey-h-100 {
    height: 100%;
}

.ey-p-20 {
    padding: 20px;
}

.mat-list-base {
    padding-top: 0 !important;
    padding-bottom: 20px;
}

/* Targeting MatSnackBar */
.mat-snack-bar-success {
    background-color: #4caf50 !important;
    /* Green for success */
    color: white !important;
}

.mat-snack-bar-error {
    background-color: #f44336 !important;
    /* Red for error */
    color: white !important;
}

::ng-deep .mat-autocomplete-panel {
    background: #000 !important;
    color: #fff !important;
}

::ng-deep .mat-option {
    font-family: EYInterstate !important;
    font-size: 12px !important;
    line-height: 40px !important;
    height: 40px !important;
}