// import custom components themes

// you only have to add additional components here (instead of in every theme class)
@use 'sass:map';
@use '@angular/material' as mat;



@mixin custom-components-theme($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $is-dark-theme: map.get($color-config, 'is-dark');

    .md-custom-tree {
        background-color: mat-color($primary-palette, background);
    }

    .mat-drawer {
        background-color: #131329;
    }

    .remove-icon,
    .iot-tree-node-connecting-line,
    .iot-tree-node-connecting-line-vertical,
    .iot-tree-node-main,
    .add-icon {
        border-color: mat-color($primary-palette, foreground);
    }

    .fa,
    .fas {
        color: mat-color($primary-palette, foreground);
    }

    // .mat-drawer-container {
    //     background-color: mat-color($primary-palette, 800) !important;
    // }
}